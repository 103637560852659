import React from 'react';
import classes from './SectionOne.module.css';
import "@google/model-viewer";
import Crypto3D from '../../../assets/images/compressed/CryptoPodzModel1.glb';

const SectionOne = () => {

	return (
		<div className={classes.SectionOne} id="section1">
			<div className={classes.Container}>
				<section className={classes.ImageBG}></section>
			</div>
			<div className={classes.PodzShowcaseContainer}>
				<div className={classes.PodzShowcase}>
					<div className={classes.MainText}>
						<div className={classes.Title}>
							<div>MEET THE</div>
							<div>CRYPTOPODZ</div>
						</div>
						<div className={classes.SubText}>
							A 3D art collectibles project dedicated to the movement.<br /><br />
							PODZ is a collection of 100 CryptoPodz NFTs.<br />
							They are unique 1/1 digital collectibles living on the Ethereum blockchain, visualizing the history and future of cryptos through the lens of CJK.<br />
							Your Pod also acts as membership card, granting access to special perks only available to owners.
						</div>
					</div>
					<div className={classes.ShowcaseImage}>
						<model-viewer
		                    src={Crypto3D}
		                    alt="A 3D model of an astronaut"
		                    ar ar-modes="webxr scene-viewer quick-look"
		                    environment-image="neutral"
		                    camera-orbit={ window.screen.width > 768 ? "30deg 80deg 120m" : "30deg 80deg 150m"}
//		                    camera-orbit='5.14rad 1.03rad 50m'
		                    camera-target="0m 15m 0m"
		                    disable-zoom
		                    camera-controls>
		                </model-viewer>
					</div>
				</div>
			</div>
		</div>
	);
}

export default SectionOne;