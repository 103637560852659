import React, { useEffect, useState, useRef } from "react";
import classes from './SectionOne.module.css';
import planet from '../../../assets/images/BattlePodz/PlanetElement.png';
import podz from '../../../assets/images/BattlePodz/Element3.gif';
import PodzImage from '../../../assets/images/BattlePodz/Element1.png';
import PodzVideo from '../../../assets/images/PodzWorld/RoamCam.mp4';
import PodzPoster from '../../../assets/images/PodzWorld/podzworld_poster.png';
import PodzPosterWebp from '../../../assets/images/podzworld_webp/podzworld_poster.webp';
import arrow from '../../../assets/images/others/chevron.png';

import loadWeb3Data from '../../ContractFunctions/Web3Custom';
import BattlePodz from '../../../abis/BattlePodz.json';
import axios from 'axios';

const SectionOne = () => {

	const videoRef = useRef();
	const [showRequirements, setShowRequirements] = useState(false);
	useEffect(() => {
		if (videoRef) {
			videoRef.current.play();
		}
	}, [])

	const gameData = {
		contractAddress: "0x41828e6edcc8173f829989993855c51c78de66c6",
		abi: BattlePodz,
		gameLinkSheet: "https://docs.google.com/spreadsheets/d/e/2PACX-1vRA0xiCzjsVuidW1QsoTPtYtdgX4bq1t6FjncQOapQe1BBd1a-RUjj4l1tUFpNZdK3lb3cyRJ-XjLdC/pub?gid=0&single=true&output=csv",
		gameButtonUnAuth: "CONNECT",
		gameButtonAuth: ["DOWNLOAD", "CREATE GAME ACCOUNT"]
	}

	const [error, setError] = useState(false);
	const [authStatus, setAuthStatus] = useState(false);
	const [gameLink, setGameLink] = useState('');

	const authMetamask = () => {
		const data = loadWeb3Data(gameData.contractAddress, gameData.abi)
		data.then(function (res) {
			res.contract.methods.balanceOf(res.account).call()
				.then(function (balance) {
					if (balance > 0) {
						setAuthStatus(true);
					} else {
						setError(true)
					}
				})
				.catch(function (err) {
					setError(true)
				})
		})
	}

	useEffect(() => {
		axios.get(gameData.gameLinkSheet)
			.then(function (res) {
				const rows = res.data.split('\r\n')
				let latestLink = rows[rows.length - 1]
				setGameLink(latestLink)
			}).catch(function (err) {
				console.log(err)
			})
	}, [])

	return (
		<div className={classes.SectionOne} id="section1">
			<div className={classes.VideoBackground}>
				<video autoPlay muted loop ref={videoRef}>
					<source src={PodzVideo} type="video/mp4" />
					Your browser does not support the video tag.
				</video>
			</div>
			<div className={classes.TitleText}>
				A NEW FRONTIER AWAITS
			</div>
			<div className={classes.SecondContainer}>
				<div className={classes.TextContainer}>
					<div className={classes.TextOne}>
						<p className={classes.HardwareSpecs} onClick={() => setShowRequirements(!showRequirements)}>SYSTEM REQUIREMENTS &nbsp; <img src={arrow} className={showRequirements ? classes.RotateImg : null} /></p>
						<ul className={!showRequirements ? classes.HideReq : null}>
							<li>MINIMUM:</li>
							<ul>
								<li>Requires a 64-bit processor and operating system</li>
								<li>OS: Windows 7/8.1/10 (64-bit versions)</li>
								<li>Processor: Intel Core i3</li>
								<li>Memory: 8 GB RAM</li>
								<li>Graphics: nVidia GTX 480, AMD Radeon 7870</li>
								<li>Storage: 10 GB available space</li>
							</ul>
						</ul>
					</div>
				</div>
				<div className={classes.ImageContainer}>
					<picture>
						<source srcset={PodzPosterWebp} />
						<img src={PodzPoster} alt="cryptopodz - podzworld poster" />
					</picture>
				</div>
			</div>
			<div className={classes.Container}>
				<div className={classes.BuyPodOpenSea}>
					<div>
						<div>ENTER WORLD</div>
						<div>Click download to go through the metamask connection and download the game</div>
					</div>
					<div>
						{error ? <div className={classes.ErrorText}>** You must own a <a href="/battlepodz">BattlePod</a> **</div> : null}
						{authStatus ?
							gameData.gameButtonAuth.map((text, i) =>
								<button key={i} onClick={text == "DOWNLOAD" ? () => window.open(gameLink) : text == "CREATE GAME ACCOUNT" ? () => window.open("http://cjkproduction.com/auth") : null}>
									{text}
								</button>)
							:
							<button onClick={authMetamask}>{gameData.gameButtonUnAuth}</button>}
					</div>
				</div>
			</div>
		</div>
	);
}

export default SectionOne;
