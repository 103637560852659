import React, { Component } from 'react';
import Web3 from 'web3';
import BattlePodz from '../../abis/BattlePodz.json';
import classes from './LoginPage.module.css';
import axios from 'axios';
import Logo from '../../assets/images/logos/favicon.png';
import CopyIcon from '../../assets/images/others/copytoclip.png';

class LoginPage extends Component {

	constructor(props){
		super(props);
		this.state = { 
		  account: '',
		  contract: null,
		  address: '',
          otp: '',
          buttonTexts: {
            connectButton: "CONNECT",
            logoutButton: "LOGOUT"
          }
        }
        this.config = {
            headers: {
                "Authorization": "Basic "+process.env.REACT_APP_API_KEY
            }
        }
    }

    resetButtonTexts = () => {
        this.setState({
        buttonTexts: {
            connectButton: "CONNECT",
            logoutButton: "LOGOUT"
        }
        })
    }

    generateOTP = (address) => {
        const thisObject = this;
        axios.post('https://yr0vvfrki4.execute-api.us-east-1.amazonaws.com/otp/generate',
        {
            "address": address
        }, this.config).then(function(res){
            if(res.data){
                thisObject.setState({otp: res.data.details.otp})
                thisObject.resetButtonTexts()
            }
        }).catch(function(err){
            console.log(err)
        })
    }

    logoutMe = (address) => {
        const thisObject = this;
        axios.post('https://yr0vvfrki4.execute-api.us-east-1.amazonaws.com/user/logout',
        {
            "address": address
        }, this.config).then(function(res){
            thisObject.setState({otp: "Successfully logged out"})
            thisObject.resetButtonTexts()
        }).catch(function(err){
            console.log(err)
        })
    }

	async loadData(action="login"){
		await this.loadWeb3()
		await this.loadBlockchainData(action)
	}

	async loadWeb3(){
    	if(window.ethereum){
    	  window.web3 = new Web3(window.ethereum)
    	  await window.ethereum.enable()
    	}
    	else if(window.web3){
    	  window.web3 = new Web3(window.web3.currentProvider)
    	}
    	else{
    	  window.alert('Non-Ethereum browser detected. You should consider trying MetaMask !')
    	}
	}

    async fetchMyDetails(contract, account){
        console.log(contract, account)
        const no_of_tokens = await contract.methods.balanceOf(account).call()
        if(no_of_tokens > 0){
            this.generateOTP(account)
            console.log(no_of_tokens, "NO OF TOKENS")
        }else{
            this.setState({otp: "Unauthorized User"})
            this.resetButtonTexts()
        }
    }

	async loadBlockchainData(action){
        const web3 = window.web3;
        if(!web3){
            return;
        }
        /* Create a signature for a message
        var message = "Some string"
        var hash = web3.utils.sha3(message)
        var accounts = await web3.eth.getAccounts()
        var signature = await web3.eth.personal.sign(hash, accounts[0])

        // Recover the address for a message + signature: You should see that signing_address will match accounts[0] if you are using the same message and signature across the board.
        //var hash = web3.utils.sha3(message)
        //var signing_address = await web3.eth.personal.ecRecover(hash, signature)*/

        if(action === "login"){
            this.setState({
                buttonTexts: {
                    connectButton: "LOADING...",
                    logoutButton: "LOGOUT"
                }
            })            
        }else if(action === "logout"){
            this.setState({
                buttonTexts: {
                    connectButton: "CONNECT",
                    logoutButton: "LOADING..."
                }
            })

        }
        const accounts = await web3.eth.getAccounts()
        this.setState({ account: accounts[0] })
        const abi = BattlePodz
        const main_address = "0x41828e6edcc8173f829989993855c51c78de66c6"
        const address = main_address
        var contract = new web3.eth.Contract(abi, address)

        this.setState({contract: contract, address: address})
        const totalSupply = await contract.methods.totalSupply().call()
        const price = await contract.methods.podzPrice().call()
        console.log(totalSupply, price)
        this.setState({totalSupply: totalSupply, price: price})

        if(action === "login"){
            await this.fetchMyDetails(contract, accounts[0])
        }else if(action === "logout"){
            this.logoutMe(accounts[0])
        }
	}

    copyFunction = () => {
        let text = this.state.account+":"+this.state.otp;

        try{
            navigator.clipboard.writeText(text).then(function() {
                alert("OTP copied to clipboard")
            }, function() {
                alert("Could not copy")
            });
        }catch(err){
            alert("Could not copy")
        }
    }

  render() {
    return (
        <div className={classes.LoginPage}>
            <div className={classes.Title}>
                <a href="/">
                    <img src={Logo} alt="" />
                    <div>PODZ WORLD</div>
                </a>
            </div>
            <div className={classes.Auth}>
                { this.state.otp === '' ? 
                <div>USER AUTHENTICATION</div>
                : this.state.otp.length === 6 ?
                <div className={classes.OTPBlock}>
                    OTP Generated for:
                    <div className={classes.OTP}>
                        <div>
                            <div className={classes.WalletID}>{this.state.account}</div>
                            <div className={classes.CopyButton}>{this.state.otp}</div>
                        </div>
                        <div onClick={this.copyFunction}><img src={CopyIcon} alt="copy icon" /></div>
                    </div>
                </div>
                : <div>{this.state.otp}</div> }
                <div className={classes.AuthButtons}>
                    <button onClick={()=>this.loadData()}>{this.state.buttonTexts.connectButton}</button>
                    <button onClick={()=>this.loadData("logout")}>{this.state.buttonTexts.logoutButton}</button>
                </div>
            </div>
        </div>
    );
  }
}


export default LoginPage;