import React from "react"
import classes from './SectionEight.module.css'
import twitter from '../../../assets/images/others/twitter.svg'
import bitcoin from '../../../assets/images/compressed/Team1_c.jpg'
import dodge from '../../../assets/images/compressed/Team2_c.jpg'
import lite from '../../../assets/images/compressed/Team3_c.jpg'

const SectionEight=()=>{
    const Cards=
        [
            {
                pic:bitcoin,
                name:"CJK",
                design:"Art",
                style:"left",
                containerStyle: {
                    paddingRight: "20%"
                },
                link: "https://twitter.com/cyrusjameskhan"
              
            },
            {
                pic:dodge,
                name:"SEUSS",
                design:"Tech",
                style:"center",
                link: "https://twitter.com/DrSeussNFT"
               
            },
            {
                pic:lite,
                name:"X",
                design:"Community",
                style:"right",
                containerStyle: {
                    paddingLeft: "20%"
                },
                link: "https://twitter.com/cyrusjameskhan"                
            }
        ]
    
   return(
    <div className={classes.SectionEight}>
       <div className={classes.Container} id="section8">
            <div className={classes.TextContainer}>
                <p>TEAM</p>
                <p>Created by a teaming up of a frontrunning cyber artist, a fintech fanatic, and X. </p>
            </div>
            <div className={classes.CardContainer}>
                {
                    Cards.map((item)=>{
                        return <div className={classes.Card} style={ window.screen.width > 768 ? {textAlign:item.style} : {}}>
                            <img src={item.pic} alt="Cryptos" style={{border:"2px solid #fff", width:"80%"}}/>
                            <div className={classes.Container2} style={ window.screen.width > 768 ? item.containerStyle : {}}>
                           <p>{item.name}</p>
                            <p>{item.design}</p>
                           <a href={item.link} className={item.name==="X" ? classes.Hide : null}> <img src={twitter} alt="twitter_image" style={{marginTop:"5px"}}/></a>
                          </div>
                        </div>
                    })
                }
            </div>
        </div>
    </div>
   )
}
export default SectionEight;