import React, {useEffect, useState} from "react";
import classes from './SectionFour.module.css';
import planet from '../../../assets/images/BattlePodz/PlanetElement.png';
import planet_webp from '../../../assets/images/battlepodz_webp/PlanetElement.webp';
import podz from '../../../assets/images/BattlePodz/Element3.gif';

const SectionFour=()=>{
    const Render=[
        {
            text:"MK I",
            number:"15 Podz",
            text2:"OG"
         
        },
        {
            text:"MK II",
            number:"35 Podz",
            text2:"Frontrunner"
        },
        {
            text:"MK III",
            number:"50 Podz",
            text2:"Defiyer"
        }
    ]
    const [classAdded, setClassAdded] = useState(false)
    useEffect(()=>{
        document.addEventListener('scroll', function(e) {
            if(document.getElementById("section4").offsetTop < window.scrollY + 500 && !classAdded){
                setClassAdded(true)
            }
        });
    },[])

    return(
    <div className={classes.SectionFour}>
        <div className={classes.Container} id="section4">
            <div className={classes.TextContainer}>
                <div className={classes.TextOne}>
                    <p>ENTER PODZ WORLD</p>
                    <p>A key component of this new collection is PodzWorld, a new virtual experience immersing you inside the home planet of the Podz.</p>
                    <p>By owning a BattlePod, you will be able to roam around, learn fun information regarding Blockchain technology, go treasure hunting, create alliances and battle other collectors inside this persistent online world.</p>
                </div>
            </div>
            <div className={classes.ImageContainer}>
                <picture>
                    <source srcset={planet_webp} />
                    <img src={planet}  alt="cryptopodz planet image"/>
                </picture>
            </div>
        </div>
        <div className={[classes.Container, classes.SecondContainer].join(' ')}>
            <div className={classes.TextContainer}>
                <div className={classes.TextOne}>
                    <p>WEB3 & UNREAL ENGINE</p>
                    <p>PodzWorld is a web-3 powered online multiplayer game being developed in Unreal Engine. It will first become available in its alpha stage, growing with time and with input from its community.</p>
                    <p className={classes.HardwareSpecs}>Recommended hardware specs</p>
                    <ul>
                        <li>OS: Windows 10</li>
                        <li>RAM: 8 Gb</li>
                        <li>Graphics: 2 Gb V-RAM</li>
                        <li>Storage: 1 Gb</li>
                    </ul>
                </div>
            </div>
            <div className={classes.ImageContainer}>
                <img src={podz}  alt="cryptopodz_image"/>
            </div>
        </div>
    </div>
    )
}
export default SectionFour;