import React from 'react';
import classes from './MintingPage.module.css';
import Logo from '../../../assets/images/logos/favicon.png';
import BuyFunction from '../../ContractFunctions/MintFunction';

const MintingPage = (props) => {

	return (
		<div className={classes.MintingPage}>
			<div className={classes.Container}>
				<div className={classes.MintingPageContent}>
					<div className={classes.Close}>
						<div onClick={props.close}>&times;</div>
					</div>
					<div className={classes.ContentContainer}>
						<div className={classes.Title}>MINT</div>
						<div className={classes.Content}>
							<div className={classes.Text}>
								{/*<div>Only 50 people will be eligible to mint during private sale, that will last 24 hours. The remaining tokens will be available during the upcoming public sales.</div>*/}
								<div>The token will be randomized and you will only know what it looks like after minting.</div>
								<div>1 person may only mint 1 token.</div>
							</div>
							<div className={classes.ImageContainer}>
								<img src={Logo} alt="CryptoPodz Logo" />
							</div>
						</div>
						<div className={classes.ActionButton}>
							<BuyFunction close={props.close} />
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}
export default MintingPage;