import './App.css';
import {Route, Switch, BrowserRouter} from 'react-router-dom';
import Home from './components/LandingPage/HomePage/Home';
import BattlePodzHome from './components/BattlePodz/HomePage/Home';
import Archive from './components/LandingPage/Archive/Archive';
import LoginPage from './components/LoginPage/LoginPage';
import PodzWorld from './components/PodzWorld/HomePage/Home';

function App() {
  return (
    <div className="App">
      <BrowserRouter>
      <Switch>
        <Route exact path="/" component={Home} />
        <Route exact path="/archive" component={Archive} />
        <Route exact path="/battlepodz" component={BattlePodzHome} />
        <Route exact path="/podzworld/login" component={LoginPage} />
        <Route exact path="/podzworld" component={PodzWorld} />
      </Switch>
      </BrowserRouter>
    </div>
  );
}

export default App;
