import React from 'react';
import classes from './SectionSeven.module.css';

const SectionSeven = () => {

	return (
		<div className={classes.SectionSeven} id="section7">
			<div className={classes.Container}>
				<div className={classes.Roadmap}>
					<div className={classes.Title}>EVOLUTION</div>
					<div className={classes.BoxContainer}>
						<div className={classes.Up}>
							<div>2021 BattlePodz Announcement</div>
							<div>2022 PodzWorld Announcement and Alpha</div>
							<div>2023 PodzWorld Update 2.0</div>
						</div>
						<div className={classes.Down}>
							<div>2021 CryptoPodz Inception</div>
							<div>2021 BattlePodz Release</div>
							<div>2022 PodzWorld R&D</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}

export default SectionSeven;
