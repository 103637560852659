import React from "react"
import classes from './SectionFive.module.css'
import podz from '../../../assets/images/compressed/collage.jpg'
import podz_webp from '../../../assets/images/compressed_webp/collage.webp'
const SectionFive=()=>{
    return(
        <div className={classes.SectionFive}>        
            <div className={classes.Container} id="section5">
                <picture>
                    <source srcset={podz_webp} />
                    <img src={podz} alt="Podz" />
                </picture>
            </div>
        </div>
    )
}
export default SectionFive;