import React from "react"
import classes from './SectionThree.module.css'
const SectionThree=()=>{
    const Render=[
        /*{
            title:"NO POOP COINS",
            content:"As long-term fanatics of the space, the projects we portray in this collection are ones we respect."
        },*/
        {
            title:"EXCLUSIVE COLLECTION",
            content:"The GEN I Collection has a max cap of 100 handcrafted tokens. Inside of that collection lies three complexity tiers."
        },
        {
            title:"PODZ CLUB",
            content:"Only GEN I owners will be considered for exclusive development updates and special rewards."
        },
        {
            title:"COPYRIGHTS?",
            content:"None given. Not that kind of project. ¯\\_(ツ)_/¯"
        },
        {
            title:"FUTURE EXPANSION",
            content:"Future Podz with evolved blueprints will be released, those will be part of the GEN II and GEN III collections."
        },
        {
            title:"METAVERSE",
            content:"Not crowdfunding here. We are making the collection stand on its own two feet before promising grandeur."
        }
    ]
    return(
    <div className={classes.SectionThree}>
        <div className={classes.Container} id="section3">
            <div className={classes.ParaContainer}>
                <p>FACTS</p>
            </div>
            <div className={classes.Ruler}>
                <hr></hr>
                <hr></hr>
            </div>
            <div className={classes.ContentRendering}>
                {Render.map((item)=>{
                         return <div className={classes.ObjectContainer}>
                              <p>{item.title}</p>
                              <p>{item.content}</p>
                              </div>
                    })}
              
            </div>
            <div className={classes.Ruler}>
                <hr></hr>
                <hr></hr>
            </div>
        </div>
    </div>
    )
}
export default SectionThree