import React, {useEffect, useState} from "react";
import classes from './SectionFour.module.css';
import podz from '../../../assets/images/others/EthGIF.gif';

const SectionFour=()=>{
    const Render=[
        {
            text:"MK I",
            number:"15 Podz",
            text2:"OG"
         
        },
        {
            text:"MK II",
            number:"35 Podz",
            text2:"Frontrunner"
        },
        {
            text:"MK III",
            number:"50 Podz",
            text2:"Defiyer"
        }
    ]
    const [classAdded, setClassAdded] = useState(false)
    useEffect(()=>{
        document.addEventListener('scroll', function(e) {
            if(document.getElementById("section4").offsetTop < window.scrollY + 500 && !classAdded){
                setClassAdded(true)
            }
        });
    },[])

    return(
    <div className={classes.SectionFour}>
        <div className={classes.Container} id="section4">
            <div className={classes.TextContainer}>
                <div className={classes.TextOne}>
                    <p>GENERATION ONE</p>
                    <p>CryptoPodz have different tiers, some more matured and complex than others.</p>
                    <p>All of the Podz' complexity have been determined based on chronological release of projects, for an unbiased design approach.</p>
                </div>
                <div className={classes.TextTwo}>
                    <div className={classes.One}>
                <p style={{marginBottom:"15px", fontFamily: "LEMONMILK_REGULAR"}}>GEN I - 100</p>
                </div>
                <div className={ classAdded ? classes.EditionsSection : null}>
                    {Render.map((item)=>{
                        return<div className={classes.ListItem}>
                          <p><span>{item.text}</span> - {item.number}</p>
                            {/*<p>{item.text2}</p>*/}
                        </div>
                    })}
                </div>
                </div>
            </div>
            <div className={classes.ImageContainer}>
                <img src={podz}  alt="cryptopodz_image"/>
            </div>
        </div>
    </div>
    )
}
export default SectionFour;