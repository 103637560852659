import React from "react";
import classes from './SectionSix.module.css';
import CPOS2 from '../../../assets/images/compressed/CPOS.glb';
import "@google/model-viewer";

const SectionSix=()=>{
    return(
        <div className={classes.SectionSix}>
            <div className={classes.Container} id="section6">
                <div className={classes.TextContainer}>
                    <p>UPGRADES</p>
                    <p>All Podz will be able to be enhanced later down the line. 
                        Make sure to watch out for the CryptoPodz Operating System (CPOS) upgrade chips!</p>

                </div>
                <div className={classes.BlankCanvas}>
                    <model-viewer
                        src={CPOS2}
                        alt="A 3D model of an astronaut"
                        ar ar-modes="webxr scene-viewer quick-look"
                        environment-image="neutral"
                        camera-orbit="0deg 80deg 400m"
                        auto-rotate
                        disable-zoom
                        camera-controls>
                    </model-viewer>
                </div>
            </div>
        </div>
    )
}
export default SectionSix