import Web3 from 'web3';


async function loadWeb3Data(contract_address, contract_abi){
	await loadWeb3()
	const state = await loadBlockchainData(contract_address, contract_abi)
    return state
}

async function loadWeb3(){
	if(window.ethereum){
	  window.web3 = new Web3(window.ethereum)
	  await window.ethereum.enable()
	}
	else if(window.web3){
	  window.web3 = new Web3(window.web3.currentProvider)
	}
	else{
	  window.alert('Non-Ethereum browser detected. You should consider trying MetaMask !')
	}
}

async function loadBlockchainData(contract_address, contract_abi){
    try{
        const web3 = window.web3;
        const accounts = await web3.eth.getAccounts()
        const abi = contract_abi
        const address = contract_address
        var contract = new web3.eth.Contract(abi, address)
        const totalSupply = await contract.methods.totalSupply().call()

        const state = {
            contract: contract,
            account: accounts[0],
            address: address,
            totalSupply: totalSupply
        }
        return state
    }catch(err){
        return {
            contract: '',
            account: '',
            address: '',
            totalSupply: 0
        }
    }
}

export default loadWeb3Data;


/*
    loadWeb3Data("0xe29a492bbf25f86b1340d7b9e440b1cda0ec3c49", CryptoPodz.abi).then(function(res){
      console.log(res)
    })
*/