import React from 'react';
import classes from './Archive.module.css';
import Header from '../../BattlePodz/Header/Header';
import SectionNine from '../SectionNine/SectionNine';
import BuyFunction from '../../ContractFunctions/BuyFunction';

const Archive = () => {

	return (
		<>
		<Header />
		<div className={classes.Archive}>
			<div className={classes.Container}>
				<BuyFunction />
			</div>
		</div>
		<SectionNine />
		</>
	);
}

export default Archive;