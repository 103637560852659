import React, { Component } from 'react';
import Web3 from 'web3';
import BattlePodz_R from '../../abis/BattlePodzRinkeby.json';
import BattlePodz from '../../abis/BattlePodz.json';
import classes from './BuyFunction.module.css';
import axios from 'axios';

class MintFunction extends Component {

	constructor(props){
		super(props);
		this.state = { 
		  account: '',
		  contract: null,
		  totalSupply: 0,
		  price: 0,
		  address: '',
		  myTokens: [],
		  formShow: false,
		  whitelisted: false
        }
    }

	async loadData(){
		await this.loadWeb3()
		await this.loadBlockchainData()
	}

	async loadWeb3(){
	if(window.ethereum){
	  window.web3 = new Web3(window.ethereum)
	  await window.ethereum.enable()
	}
	else if(window.web3){
	  window.web3 = new Web3(window.web3.currentProvider)
	}
	else{
	  window.alert('Non-Ethereum browser detected. You should consider trying MetaMask !')
	}
	}

	async loadBlockchainData(){
	  const web3 = window.web3;
	  const accounts = await web3.eth.getAccounts()
	  this.setState({ account: accounts[0] })
	  //const gas = await web3.eth.getGasPrice();
	  //console.log(gas)
	  //this.setState({gasPrice: gas})
	  //const networkId = await web3.eth.net.getId()
	  //const networkData = BattlePodz.networks[networkId]
	  //if(networkData){
	    const abi = BattlePodz
	    const test_address = "0xD8E5268dC9A4c45333a4aE180D981F59f1f7E33C"
	    const main_address = "0x41828e6edcc8173f829989993855c51c78de66c6"
	    const address = main_address
	    var contract = new web3.eth.Contract(abi, address)

	    this.setState({contract: contract, address: address})
	    const totalSupply = await contract.methods.totalSupply().call()
	    const price = await contract.methods.podzPrice().call()
	    console.log(totalSupply, price)
	    this.setState({totalSupply: totalSupply, price: price})

	    const saleTime = await contract.methods.saleFinishTime().call()
	    if(saleTime > new Date().getTime()/1000){
	    	const whitelist = await contract.methods.whitelistedAddresses(accounts[0]).call()
	    	if(whitelist){
	    		this.setState({whitelisted: true})	
	    	}else{
	    		this.setState({whitelisted: false})	
	    	}
	    }else{
	    	this.setState({whitelisted: true})
	    }
	    this.fetchMyDetails()
	}

	mint = (numberOfTokens) => {
		this.state.contract.methods.mintPodz(numberOfTokens).send({from: this.state.account, value: this.state.price*numberOfTokens})
		.once('receipt', (receipt)=> {
		    console.log("minted")
		    this.fetchMyDetails()
		})
	}

	mintPod = (event) =>{
	    event.preventDefault();
        let amount = 1;
	    if(amount && this.state.contract){
	    	this.mint(amount);
	    }else{
	    	alert("Connect to your wallet and choose the number of tokens to mint");
	    	this.props.close();
	    }
	}

	fetchMyDetails = () => {
		const state = this.state;
		const thisS = this;
		const testnet = "https://testnets.opensea.io/assets/";
		const mainnet = "https://opensea.io/assets/";
		if(state.contract){
			state.contract.methods.totalMinted(state.account).call()
			.then(function(balance){
				for(let i=0; i<balance; i++){
					console.log(i)
					state.contract.methods.tokenOfOwnerByIndex(state.account, i).call()
					.then(function(tokenId){
						thisS.setState(prevState => ({ myTokens: [...prevState.myTokens, mainnet+state.address+"/"+tokenId]}))
					})
				}
			})
            thisS.setState({formShow: true})
		}
	}

  render() {
    return (
        <div className={classes.MintForm}>
        {console.log(this.state)}
            { this.state.contract ?
            this.state.formShow && this.state.myTokens.length == 0 ?
            this.state.totalSupply < 500 ?
                this.state.whitelisted ?
                    <form onSubmit={this.mintPod}>
                        <div className={classes.SubmitButton}>
                            <button type="submit">MINT</button>
                        </div>
                    </form>
                : <div className={classes.NotWhitelisted}>User not whitelisted</div>
            : <div className={classes.CheckOpensea}>
                    All tokens have been minted. Please check the collection on&nbsp;
                    <a href="https://opensea.io/collection/battlepodz" target="_blank">Opensea</a>
                </div>
            : null
            :   <div className={classes.SubmitButton}>
                <button onClick={()=>this.loadData()}>Connect to wallet</button>
            </div> }

            { this.state.myTokens.length ?
                <div className={classes.MyTokens}>
                    Thank you for your purchase.<br />
                    All the metadata will be available on the website shortly after the sale.<br />
                    You may view the BattlePodz collection on <a href="https://opensea.io/collection/battlepodz" target="_blank">OpenSea</a>.<br /><br />
                    Minted Podz
                    {
                        this.state.myTokens.map(function(data, index){
                            return <div><a href={data}>{"Pod "+(index+1)}</a></div>;
                        })
                    }
                    <div className={classes.PS}>**You may view the BattlePodz collection on OpenSea. All of the Podz will be displayed on the website soon.</div>
                </div> : null
            }
        </div>
    
    );
  }
}

export default MintFunction;