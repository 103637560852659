import React from 'react';
import classes from './SectionOne.module.css';

import PodzImage from '../../../assets/images/BattlePodz/Element1.png';
import PodzImageWebp from '../../../assets/images/battlepodz_webp/Element1.webp';

const SectionOne = () => {

	return (
		<div className={classes.SectionOne} id="section1">
			<div className={classes.Container}>
				<section className={classes.ImageBG}></section>
			</div>
			<div className={classes.PodzShowcaseContainer}>
				<div className={classes.PodzShowcase}>
					<div className={classes.MainText}>
						<div className={classes.Title}>
							<div>THE</div>
							<div>BATTLEPODZ</div>
						</div>
						<div className={classes.SubText}>
							Introducing the CryptoPodz Generation II, BattlePodz. 1/1 digital collectibles living on the Ethereum blockchain. <br /><br />
							Time has passed, the original titans have returned to their homeworld in distress. With news of an upcoming threat rising, the BattlePodz have been created in hopes to protect and continue the expansion of PodzWorld.
						</div>
					</div>
					<div className={classes.ShowcaseImage}>
						<picture>
							<source srcset={PodzImageWebp} />
							<img src={PodzImage} alt="podz image CryptoPodz battlepodz" />
						</picture>
					</div>
				</div>
			</div>
		</div>
	);
}

export default SectionOne;
