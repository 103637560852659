import React from "react"
import classes from './SectionFive.module.css'

import AWS from '../../../assets/images/PodzWorld/aws.png';
import ETH from '../../../assets/images/PodzWorld/ethereum-logo.png';
import IPFS from '../../../assets/images/PodzWorld/ipfs-logo.png';
import METAMASK from '../../../assets/images/PodzWorld/metamask.png';
import UE from '../../../assets/images/PodzWorld/unreal-engine.png';
import MORALIS from '../../../assets/images/PodzWorld/moralis.png';


import AWS_WEBP from '../../../assets/images/podzworld_webp/aws.webp';
import ETH_WEBP from '../../../assets/images/podzworld_webp/ethereum-logo.webp';
import IPFS_WEBP from '../../../assets/images/podzworld_webp/ipfs-logo.webp';
import METAMASK_WEBP from '../../../assets/images/podzworld_webp/metamask.webp';
import UE_WEBP from '../../../assets/images/podzworld_webp/unreal-engine.webp';
import MORALIS_WEBP from '../../../assets/images/podzworld_webp/moralis.webp';


const SectionFive=()=>{
    return(
        <div className={classes.SectionFive}>        
            <div className={classes.Container} id="section5">
                <div>POWERED BY</div>
                <div className={classes.Logos}>
                    <div>
                        <a href="https://ethereum.org/en/" rel="noreferrer">
                            <picture>
                                <source srcset={ETH_WEBP} />
                                <img src={ETH} alt="ETH" />
                            </picture>
                        </a>
                    </div>
                    <div>
                        <a href="https://metamask.io" rel="noreferrer">
                            <picture>
                                <source srcset={METAMASK_WEBP} />
                                <img src={METAMASK} alt="METAMASK" />
                            </picture>
                        </a>
                    </div>
                    <div>
                        <a href="https://moralis.io" rel="noreferrer">
                            <picture>
                                <source srcset={MORALIS_WEBP} />
                                <img src={MORALIS} alt="MORALIS" />
                            </picture>
                        </a>
                    </div>
                    <div>
                        <a href="https://ipfs.io" rel="noreferrer">
                            <picture>
                                <source srcset={IPFS_WEBP} />
                                <img src={IPFS} alt="IPFS" />
                            </picture>
                        </a>
                    </div>
                    <div>
                        <a href="https://www.unrealengine.com/en-US/" rel="noreferrer">
                            <picture>
                                <source srcset={UE_WEBP} />
                                <img src={UE} alt="UE" />
                            </picture>
                        </a>
                    </div>
                    <div>
                        <a href="https://aws.amazon.com" rel="noreferrer">
                            <picture>
                                <source srcset={AWS_WEBP} />
                                <img src={AWS} alt="AWS" />
                            </picture>
                        </a>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default SectionFive;