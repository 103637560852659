import React from "react"
import classes from './SectionFive.module.css'

const SectionFive=()=>{
    return(
        <div className={classes.SectionFive}>        
            <div className={classes.Container} id="section5">
                <div>RELEASE DATE</div>
                <div>PRIVATE SALE 25TH MARCH & PUBLIC SALE 26TH MARCH</div>
            </div>
        </div>
    )
}
export default SectionFive;